import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from 'react';
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    p: "p",
    pre: "pre",
    ..._provideComponents(),
    ...props.components
  }, {LolFlexQueue, LolRGM, ProfileColumn, ProfileRank} = _components;
  if (!LolFlexQueue) _missingMdxReference("LolFlexQueue", true);
  if (!LolRGM) _missingMdxReference("LolRGM", true);
  if (!ProfileColumn) _missingMdxReference("ProfileColumn", true);
  if (!ProfileRank) _missingMdxReference("ProfileRank", true);
  if (!ProfileRank.Accordion) _missingMdxReference("ProfileRank.Accordion", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import ProfileRank from \"@/shared/ProfileRank.jsx\";\nimport { ProfileColumn } from \"@/shared/Profile.style.jsx\";\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Most (if not all) player profiles for a game represent a singlar player. Many of the titles we support (like League) have ranks for the more competitive modes. This is represented by a ", _jsx(_components.code, {
        children: "<ProfileRank />"
      }), ". The ProfileRank displays some data about that particular game mode with icons/images and numbers for the rank. In the case where a game has multiple ranked modes, typically there is a \"preferred/primary\" mode. This is the one that we represent with ", _jsx(_components.code, {
        children: "<ProfileRank />"
      }), " and the others are represented with ", _jsx(_components.code, {
        children: "<ProfileRank.Accordion />"
      }), " below the primary rank"]
    }), "\n", _jsx(_components.h2, {
      id: "example-profile-rank-league-of-legends",
      children: _jsx(_components.a, {
        href: "#example-profile-rank-league-of-legends",
        children: "Example Profile Rank (League of Legends)"
      })
    }), "\n", _jsx(_components.code, {
      preview: true,
      className: "language-jsx",
      code: `<ProfileColumn>
  <ProfileRank
    title="Ranked Solo"
    image="https://blitz-cdn.blitz.gg/80x0/ranks/2023/emerald.webp"
    name="Emerald 4"
    points="38 LP"
    percentFill={0.38}
    color="#56d744"
    wins={33}
    losses={29}
  />
  <ProfileRank.Accordion
    ranksList={[
      {
        label: "Ranked Flex",
        wins: 2,
        losses: 0,
        percentFill: 1,
        points: 87,
        rankName: "Gold 3",
        summaryColor: "#f1a64e",
        barColor: "var(--perf-pos3)",
        queueIcon: <LolFlexQueue />
      },
      {
        label: "Arena",
        wins: 3,
        losses: 5,
        percentFill: 0.75,
        points: 1596,
        rankName: "Bronze 1596",
        summaryColor: "#b97452",
        barColor: "var(--perf-neg2)",
        queueIcon: <LolRGM />
      }
    ]}
  />
</ProfileColumn>`,
      children: _jsxs(ProfileColumn, {
        children: [_jsx(ProfileRank, {
          title: "Ranked Solo",
          image: "https://blitz-cdn.blitz.gg/80x0/ranks/2023/emerald.webp",
          name: "Emerald 4",
          points: "38 LP",
          percentFill: 0.38,
          color: "#56d744",
          wins: 33,
          losses: 29
        }), _jsx(ProfileRank.Accordion, {
          ranksList: [{
            label: "Ranked Flex",
            wins: 2,
            losses: 0,
            percentFill: 1,
            points: 87,
            rankName: "Gold 3",
            summaryColor: "#f1a64e",
            barColor: "var(--perf-pos3)",
            queueIcon: _jsx(LolFlexQueue, {})
          }, {
            label: "Arena",
            wins: 3,
            losses: 5,
            percentFill: 0.75,
            points: 1596,
            rankName: "Bronze 1596",
            summaryColor: "#b97452",
            barColor: "var(--perf-neg2)",
            queueIcon: _jsx(LolRGM, {})
          }]
        })]
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
